import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from "gatsby-plugin-google-gtag"
import * as styles from './linkElement.module.scss'
import iconArrow from '../../../assets/icon/arrow.svg'

export default function LinkElement( props ) {

    const { slug, url, title, isExternal,description } = props
    return (
        <div className = { styles.container }>
            {
                isExternal ? 
                <OutboundLink href = {url} 
                   target = "_blank"
                   rel="noreferrer"> {title} </OutboundLink>
                :
                <Link to = {slug} > {title} </Link>
            }

            <div className = {styles.descRow}>

                {
                    isExternal?
                    <OutboundLink href = {url} 
                       target = "_blank"
                       rel="noreferrer"> 
                        {description}  
                        <img src = {iconArrow} alt = "" />
                    </OutboundLink>
                    :
                    <Link to = {slug} > 
                        {description} 
                        <img src = {iconArrow} alt = "" />  
                    </Link>
                }

            </div>
        </div>
    )

}
