import React, {useEffect, useRef} from 'react'
import Layout from '../layout'
import { graphql } from 'gatsby'
import * as styles from './subservice.module.scss'
import Card from '../components/Card'
import Blog from './components/Blog'
import { gsap, Power3} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import LinksCard from './components/LinksCard/LinksCard'

gsap.registerPlugin(ScrollTrigger)

const SubServices = ( { data: {contentfulSubPagesOfServicesPage}} ) => {

    let subPage = useRef(null)

    useEffect( ()=>{
      gsap.from(subPage,{
        scrollTrigger:{
            trigger: subPage,
            start: 'top 90%',
            end: 'bottom 70%',
            toggleActions: 'play none none none',
        },
        opacity: 0,
        y:100,
        duration:1.5,
        ease:Power3.easeInOut,
    })
  },[])

    const { headerTitle, content } = contentfulSubPagesOfServicesPage

    return (

        <Layout title = {headerTitle} subPageHero >
            
            <section className = {styles.sub_services_page}
                     ref = { el => { subPage = el }}>

                <div className = {styles.cardWrapper}>
                    <Card />
                </div>

                <div className = {styles.blogWrapper}>
                    <Blog {...content} />
                </div>

                <div className = {styles.linksCardWrapper}>
                    <LinksCard />
                </div>

            </section>

        </Layout>
    )
}

export const query = graphql`
    query subSerivcesPages($slug: String) {
        contentfulSubPagesOfServicesPage(slug: {slug: {eq: $slug}}) {
        headerTitle
        content {
            raw
        }
        slug {
            title
            slug
        }
        }
    }  
`

export default SubServices
