import React from 'react'
import {graphql, useStaticQuery } from 'gatsby'
import * as styles from './linkscard.module.scss'
import LinkElement from './LinkElement'


export default function LinksCard() {

    const {title, description, links} = useStaticQuery(getLinksCard).allContentfulLinksCard.nodes[0]

    return (
        <div className = {styles.container}>
           <h2 className = {styles.title}> {title} </h2>
           <div className = {styles.linksContainer}>
                {
                    links.map( ({...link}) => 
                        <LinkElement 
                            {...link} 
                            description = {description}
                            key = {link.contentful_id}  /> )
                }
           </div>
        </div>
    )
}

const getLinksCard = graphql`
query linksCard {
    allContentfulLinksCard {
      nodes {
        title
        description
        links {
          contentful_id
          slug
          isExternal
          title
        }
      }
    }
  }
  
  
`