import React from 'react'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import * as styles from './blog.module.scss'

export default function Blog( {...content} ) {

    const blogRichText = content

    
    return (
        <div className = {styles.container}>

            {renderRichText(blogRichText)}

        </div>
    )
}
